import { useNavigate } from 'react-router-dom'

interface LoginCallbackProps {
    loadingElement?: JSX.Element;
    errorComponent: (error: Error) => JSX.Element;
}

const LoginCallback = ({ loadingElement, errorComponent }: LoginCallbackProps) => {
    
    const navigate = useNavigate();
    
    navigate('/home')
    

    return null; // Nothing to show in case of a successful login, as the user will be redirected
};

export default LoginCallback;
