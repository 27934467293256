import { PageSize } from 'components';

export const getDefaultSizePerPageList = (
    data: any[],
    size?: number,
    isExcludeAllPaginationSize?: boolean
): PageSize[] => {
    const defaultList = [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
        { text: '250', value: 250 },
        { text: '500', value: 500 },
        { text: '1000', value: 1000 },
    ];

    if (isExcludeAllPaginationSize) {
        return [
            ...defaultList,
            { text: '1500', value: 1500 },
            { text: '2000', value: 2000 },
            { text: '2500', value: 2500 },
            { text: '5000', value: 5000 },
        ];
    }

    return [...defaultList, { text: 'All', value: size ? size : data.length }];
};
