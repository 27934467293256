import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { initDomains } from 'redux/actions';
import { useRedux } from 'hooks';
import { useAuth } from 'react-oidc-context';

interface ProtectedRouteProps {
    component: React.ComponentType<any>;
    roles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, roles }) => {
    const { appSelector, dispatch } = useRedux();
    const [hasRequiredRole, setHasRequiredRole] = useState<boolean | null>(null); // Use null for "unknown" state

    const auth = useAuth()

    const { domains, authToken } = appSelector((state) => ({
        domains: state.Domains.list,
        authToken: state.Auth.authorization.token,
    }));

    useEffect(() => {
        if (!auth.isAuthenticated) {
            setHasRequiredRole(null); // User not loaded yet
            return;
        }

        const rolesClaimName = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
        const userRoles = auth?.user?.profile[rolesClaimName] as string[] || []

        const hasRole = roles.some((role) => userRoles.includes(role))

        setHasRequiredRole(hasRole)
    }, [auth, roles]);


    if (hasRequiredRole === null) {
        // If the role-checking is still pending (due to user data), show a loading indicator
        return <div>Loading...</div>;
    }

    // init domains
    if (!domains && hasRequiredRole) {
        initDomains();
    }

    return hasRequiredRole ? <Component /> : <Navigate to="/access/denied" />;
};

export default ProtectedRoute;
