import React, { Suspense, useEffect, useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import {
    LayoutTypes,
    MANAGER_ROLE,
    OPERATIONS_ROLE,
    ROUTING_ROLE,
    ROUTING_OLD_TEMP_ROLE,
    SALES_ROLE,
    CUSTOMER_SERVICE_ROLE, ILP_CROSSROADS_COURIER_ROLE, ILP_JUST_AN_IDEA_ROLE, ILP_ROLE,
} from 'appConstants';
import { useRedux } from 'hooks';
import RedirectLogout from 'pages/account/RedirectLogout';
import LocalStorage from 'helpers/api/LocalStorage';
import Redirecting from 'pages/account/Redirecting';
import LoginCallback from './LoginCallback';
import { oAuthLogin } from 'redux/actions';
import ProtectedRoute from './ProtectedRoute';

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const AccessDenied = React.lazy(() => import('pages/account/AccessDenied'));
const ProtectedRouteAccessDenied = React.lazy(() => import('pages/account/ProtectedRouteAccessDenied'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));
const AuthError = React.lazy(() => import('pages/account/AuthError'));

const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const LockScreen2 = React.lazy(() => import('pages/account/LockScreen2'));

// apps
const CalendarApp = React.lazy(() => import('pages/apps/Calendar'));
const ProjectList = React.lazy(() => import('pages/apps/Projects/List'));
const ProjectDetail = React.lazy(() => import('pages/apps/Projects/Detail/'));
const ProjectGannt = React.lazy(() => import('pages/apps/Projects/Gantt'));
const ProjectForm = React.lazy(() => import('pages/apps/Projects/ProjectForm'));

// - chat
const ChatApp = React.lazy(() => import('pages/apps/Chat/'));

// -crm
const CRMDashboard = React.lazy(() => import('pages/apps/CRM/Dashboard'));
const CRMProjects = React.lazy(() => import('pages/apps/CRM/Projects'));
const CRMManagement = React.lazy(() => import('pages/apps/CRM/Management'));
const CRMClients = React.lazy(() => import('pages/apps/CRM/Clients'));
const CRMOrderList = React.lazy(() => import('pages/apps/CRM/OrderList'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('pages/apps/Ecommerce/Sellers'));

// - email
const Inbox = React.lazy(() => import('pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('pages/apps/Email/Detail'));

// - social
const SocialFeed = React.lazy(() => import('pages/apps/SocialFeed/'));

// - tasks
const TaskList = React.lazy(() => import('pages/apps/Tasks/List/'));
const TaskDetails = React.lazy(() => import('pages/apps/Tasks/Details'));
const Kanban = React.lazy(() => import('pages/apps/Tasks/Board/'));
// - file
const FileManager = React.lazy(() => import('pages/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('pages/profile'));
const Profile2 = React.lazy(() => import('pages/profile2'));
const ProfileOkta = React.lazy(() => import('pages/profile-okta'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

//Role - Menagments
const Groups = React.lazy(() => import('pages/roles-managment/Groups/Groups'));
const Permissions = React.lazy(() => import('pages/roles-managment/Permissions/Permissions'));

//customer - services
const ServiceLevelExceptions = React.lazy(() => import('pages/customer-services/service-levels-exceptions'));
// const TrackShipment = React.lazy(() => import('pages/customer-services/track-shipment'));
const TrackShipments = React.lazy(() => import('pages/customer-services/track-shipments'));

const ServiceLevels = React.lazy(() => import('pages/customer-services/service-levels'));
const SftpView = React.lazy(() => import('pages/customer-services/sftp/SftpView'));
const EventTypes = React.lazy(() => import('pages/customer-services/event-types/EventTypes'));
const AddressCorrectionsNotifications = React.lazy(
    () => import('pages/customer-services/address-corrections-notifications/AddressCorrectionsNotifications')
);
const PodReview = React.lazy(() => import('pages/customer-services/pod-review/PodReview'));

// Routing
const Pickups = React.lazy(() => import('pages/routing/pickups'));
const PickupsSorting = React.lazy(() => import('pages/routing/pickups-sorting'));
const RoutingTasks = React.lazy(() => import('pages/routing/routing-tasks/RoutingTasks'));
const RouteSolutions = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutions'));
const RouteSolutionsV2 = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutionsV2'));
const RouteSolutionMap = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutionMap'));
const RouteSolutionDetailV2 = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutionDetailV2'));
const FleetView = React.lazy(() => import('pages/routing/fleet-view/FleetView'));
const ZipBasedRouting = React.lazy(() => import('pages/fleet-ops/zip-based-routing'));
const DriverAssignment = React.lazy(() => import('pages/fleet-ops/driver-assignment'));
const DriverManagement = React.lazy(() => import('pages/fleet-ops/driver-management'));
const PackageInquiry = React.lazy(() => import('pages/fleet-ops/package-inquiry'));

const ThirdPartyRouting = React.lazy(() => import('pages/routing/Third-party-routing/ThirdPartyRouting'));

// Dashboards
const WarehouseMetrics = React.lazy(() => import('pages/dashboard/warehouse-metrics/WarehouseMetrics'));

//Rates Management
const BasicRates = React.lazy(() => import('pages/rates-management/BasicRates'));
//Dynamic Pricing
const AllPricingRules = React.lazy(() => import('pages/dynamic-pricing/AllPricingRules'));
const AllCompanyPricingRules = React.lazy(() => import('pages/dynamic-pricing/AllCompanyPricingRules'));
const CreatePricingRule = React.lazy(() => import('pages/dynamic-pricing/CreatePricingRule'));
const ViewActivePricingRule = React.lazy(() => import('pages/dynamic-pricing'));
const CompanyDiscounts = React.lazy(() => import('pages/dynamic-pricing/GrossPrice'));

// Better Trucks
const Companies = React.lazy(() => import('pages/better-trucks/companies/Companies'));
const CompanyDetails = React.lazy(() => import('pages/better-trucks/companies/CompanyDetails'));
const Cities = React.lazy(() => import('pages/better-trucks/cities/Cities'));
const Shipments = React.lazy(() => import('pages/better-trucks/shipments/index'));
const ELSRoutingTasks = React.lazy(() => import('pages/better-trucks/routing-tasks/index'));
const Home = React.lazy(() => import('pages/home/simple-routing-tasks/index'));
const UnAttendedPackages = React.lazy(() => import('pages/home'));
const TemplatesTasks = React.lazy(() => import('pages/better-trucks/templates/index'));

const Claims = React.lazy(() => import('pages/better-trucks/claims'));

//Third Party Carriers
const Carriers = React.lazy(() => import('pages/third-party-carriers/carriers/Carriers'));
const CarrierZips = React.lazy(() => import('pages/third-party-carriers/carrier-zips/CarrierZips'));

const CompanyHubs = React.lazy(() => import('pages/better-trucks/hubs/CompanyHubs'));
const Users = React.lazy(() => import('pages/better-trucks/users/Users'));
const ZipCodes = React.lazy(() => import('pages/better-trucks/zip-codes/ZipCodes'));
const ZipCodeManagement = React.lazy(() => import('pages/better-trucks/zip-codes-exception/ZipCodeException'));
const ModifyZipcodes = React.lazy(() => import('pages/better-trucks/modify-zip-codes/index'));
const Vehicles = React.lazy(() => import('pages/better-trucks/vehicles/Vehicles'));
const OpsHubs = React.lazy(() => import('pages/orphan-labels/ops-hubs'));

// Orphan Labels
//const OrphanLabels = React.lazy(() => import('pages/orphan-labels/verify-labels/OrphanLabels'));
const OrphansPackage = React.lazy(() => import('pages/orphan-labels/verify-label-add-package/OrphansPackage'));
//const APICustomers = React.lazy(() => import('pages/orphan-labels/api-customers'));
const ContainerLabels = React.lazy(() => import('pages/orphan-labels/container-labels'));
const Labels = React.lazy(() => import('pages/orphan-labels/labels'));
const ContainerParcels = React.lazy(() => import('pages/orphan-labels/labels/ContainerParcels'));
const CompanyContainerLabels = React.lazy(() => import('pages/orphan-labels/container-company'));
const TrackingContainers = React.lazy(() => import('pages/orphan-labels/tracking-containers'));
const ContainerHubConfig = React.lazy(() => import('pages/orphan-labels/hub-metro-mapping'));
const CompanyContainerConfig = React.lazy(() => import('pages/orphan-labels/multi-company-container'));
const LineHaulConfig = React.lazy(() => import('pages/orphan-labels/line-haul'));

// - other
const Invoice = React.lazy(() => import('pages/other/Invoice'));
const FAQ = React.lazy(() => import('pages/other/FAQ'));
const Pricing = React.lazy(() => import('pages/other/Pricing'));
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));
const Starter = React.lazy(() => import('pages/other/Starter'));
const PreLoader = React.lazy(() => import('pages/other/PreLoader/'));
const Timeline = React.lazy(() => import('pages/other/Timeline'));

const Landing = React.lazy(() => import('pages/landing/'));

// uikit
const Accordions = React.lazy(() => import('pages/uikit/Accordions'));
const Alerts = React.lazy(() => import('pages/uikit/Alerts'));
const Avatars = React.lazy(() => import('pages/uikit/Avatars'));
const Badges = React.lazy(() => import('pages/uikit/Badges'));
const Breadcrumbs = React.lazy(() => import('pages/uikit/Breadcrumb'));
const Buttons = React.lazy(() => import('pages/uikit/Buttons'));
const Cards = React.lazy(() => import('pages/uikit/Cards'));
const Carousels = React.lazy(() => import('pages/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('pages/uikit/Dropdowns'));
const EmbedVideo = React.lazy(() => import('pages/uikit/EmbedVideo'));
const Grid = React.lazy(() => import('pages/uikit/Grid'));
const ListGroups = React.lazy(() => import('pages/uikit/ListGroups'));
const Modals = React.lazy(() => import('pages/uikit/Modals'));
const Notifications = React.lazy(() => import('pages/uikit/Notifications'));
const Offcanvases = React.lazy(() => import('pages/uikit/Offcanvas'));
const Placeholders = React.lazy(() => import('pages/uikit/Placeholders'));
const Paginations = React.lazy(() => import('pages/uikit/Paginations'));
const Popovers = React.lazy(() => import('pages/uikit/Popovers'));
const Progress = React.lazy(() => import('pages/uikit/Progress'));
const Ribbons = React.lazy(() => import('pages/uikit/Ribbons'));
const Spinners = React.lazy(() => import('pages/uikit/Spinners'));
const Tabs = React.lazy(() => import('pages/uikit/Tabs'));
const Tooltips = React.lazy(() => import('pages/uikit/Tooltips'));
const Typography = React.lazy(() => import('pages/uikit/Typography'));
const DragDrop = React.lazy(() => import('pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('pages/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('pages/uikit/Ratings'));

// icons
const Dripicons = React.lazy(() => import('pages/icons/Dripicons'));
const MDIIcons = React.lazy(() => import('pages/icons/MDIIcons'));
const Unicons = React.lazy(() => import('pages/icons/Unicons'));

// maps
const GoogleMaps = React.lazy(() => import('pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('pages/maps/VectorMaps'));

const loading = () => <div className="">...</div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={<div>Loading...</div>}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();
    // const { user } = useAuth0();

    const { layout, user } = appSelector((state) => ({
        layout: state.Layout,
        user: state.Auth.user,
    }));

    // console.log('[AllRoutes] - user', user);

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'login',
                    children: [
                        {
                            path: 'callback',
                            element: (
                                <LoginCallback
                                    loadingElement={<Redirecting />}
                                    errorComponent={(data) => {
                                        LocalStorage.setOktaExceptionMessage(data);
                                        return RedirectLogout({ url: `account/auth-error` });
                                    }}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'access-denied', element: <LoadComponent component={AccessDenied} /> },
                        { path: 'auth-error', element: <LoadComponent component={AuthError} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // auth protected routes (check for roles) [OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: 'home',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={Home}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'routing',
                    children: [
                        {
                            path: 'routing-tasks',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={RoutingTasks}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'pickups',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Pickups}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'pickups-sorting',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={PickupsSorting}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'routing-solutions/routing-solutions-details',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={RouteSolutionMap}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'routing-solutions/routing-solutions-detailsV2',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={RouteSolutionDetailV2}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: '3rd-party-routing',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ThirdPartyRouting}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                    ],
                },
                {
                    path: 'dashboards',
                    children: [
                        {
                            path: 'warehouse-metrics',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={WarehouseMetrics}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                    ],
                },
                {
                    path: 'fleet-ops',
                    children: [
                        {
                            path: 'fleet-view',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={FleetView}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                            ILP_ROLE
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'zip-based-routing',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ZipBasedRouting}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'driver-assignment',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={DriverAssignment}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'driver-management',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={DriverManagement}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                            ILP_ROLE
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'package-inquiry',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={PackageInquiry}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                    ],
                },
                {
                    path: 'better-trucks',
                    children: [
                        {
                            path: 'companies',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Companies}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'companies/:companyId',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={CompanyDetails}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'cities',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Cities}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'company-hubs',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={CompanyHubs}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'users',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Users}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'zip-codes',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ZipCodes}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'zip-code-exceptions',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ZipCodeManagement}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'modify-zip-code',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ModifyZipcodes}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'vehicle-templates',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Vehicles}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'shipments',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Shipments}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'routing-tasks',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ELSRoutingTasks}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'templates',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={TemplatesTasks}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'ops-hubs',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={OpsHubs}
                                        roles={
                                            [
                                                OPERATIONS_ROLE,
                                                ROUTING_ROLE,
                                                SALES_ROLE,
                                                MANAGER_ROLE,
                                                CUSTOMER_SERVICE_ROLE,
                                            ] // Only users with these roles can access
                                        }
                                        
                                    />
                                </Suspense>
                            ),
                        },
                    ],
                },
                {
                    path: 'customer-service',
                    children: [
                        {
                            path: 'pod-review',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={PodReview}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'claims',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Claims}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'service-levels',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ServiceLevels}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        // {
                        //     path: 'track-shipment',
                        //     element: <LoadComponent component={TrackShipment} />,
                        // },
                        {
                            path: 'track-shipments',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={TrackShipments}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'service-levels-exceptions',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ServiceLevelExceptions}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'sftp',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={SftpView}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'event-types',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={EventTypes}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'address-corrections-notifications',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={AddressCorrectionsNotifications}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                    ],
                },
                {
                    path: 'rates-management',
                    children: [
                        {
                            path: 'basic-rates',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={BasicRates}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                    ],
                },

                {
                    path: 'dynamic-pricing',
                    children: [
                        {
                            path: 'all-pricing-rules',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={AllPricingRules}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'all-company-pricing-rules',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={AllCompanyPricingRules}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'pricing-rule',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={CreatePricingRule}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'active-pricing-rules',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={ViewActivePricingRule}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        // {
                        //     path: 'net-base-rate',
                        //     element: <LoadComponent component={CompanyDiscounts} />,
                        // },
                    ],
                },
                // {
                //     path: 'verify-label',
                //     element: <LoadComponent component={OrphanLabels} />,
                // },
                {
                    path: 'verify-label-add-package',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={OrphansPackage}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                // {
                //     path: 'add-package',
                //     element: <LoadComponent component={APICustomers} />,
                // },
                {
                    path: 'container-label',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={ContainerLabels}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'labels',
                    // element: <LoadComponent component={Labels} />,
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={Labels}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'labels/:btContainerId/details',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={ContainerParcels}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'company-container-label',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={CompanyContainerLabels}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'track-containers',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={TrackingContainers}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'container-hub-config',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={ContainerHubConfig}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'multi-company-config',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={CompanyContainerConfig}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'line-haul-config',
                    element: (
                        <Suspense fallback={loading}>
                            <ProtectedRoute
                                component={LineHaulConfig}
                                roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]} // Only users with these roles can access
                                
                            />
                        </Suspense>
                    ),
                },
                {
                    path: 'third-party-carriers',
                    children: [
                        {
                            path: 'carriers',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={Carriers}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                        {
                            path: 'carrier-zips',
                            element: (
                                <Suspense fallback={loading}>
                                    <ProtectedRoute
                                        component={CarrierZips}
                                        roles={[
                                            OPERATIONS_ROLE,
                                            ROUTING_ROLE,
                                            SALES_ROLE,
                                            MANAGER_ROLE,
                                            CUSTOMER_SERVICE_ROLE,
                                        ]} // Only users with these roles can access
                                        
                                    />
                                </Suspense>
                            ),
                        },
                    ],
                },
                {
                    path: 'access',
                    children: [
                        {
                            path: 'denied',
                            element: <LoadComponent component={ProtectedRouteAccessDenied} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
