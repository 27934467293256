const config = {
    API_URL: process.env.REACT_APP_API_BASE_URL,
    CLIENT_ID : process.env.REACT_APP_CLIENT_ID,
    GOOGLE_MAPS_API_KEY : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    GOOGLE_MAPS_MAP_ID : process.env.REACT_APP_GFE_MAP_ID || '',
    GFE_BASE_URL: process.env.REACT_APP_GFE_BASE_URL || '',
    GFE_CONTENT_URL: process.env.REACT_APP_GFE_CONTENT_URL || '',
    GFE_TOKEN_URL: process.env.REACT_APP_GFE_TOKEN || '',
    PROJECT_ID: process.env.REACT_APP_PROJECT_ID || '',
    TRACKING_URL : process.env.REACT_APP_TRACKING_URL,
    OKTA_CLIENT_ID: process.env.REACT_APP_OKTA_CLIENT_ID,
    OKTA_CLIENT_SECRET: process.env.REACT_APP_OKTA_CLIENT_SECRET,
    OKTA_ISSUER: process.env.REACT_APP_OKTA_ISSUER,
    OKTA_AUDIENCE: process.env.REACT_APP_OKTA_AUDIENCE,
    GOOGLE_ANALYTICS_TRACKING_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    ENV: process.env.REACT_APP_ENV,
    FIREBASE_DATABASEURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
    FIREBASE_APIKEY: process.env.REACT_APP_FIREBASE_APIKEY,
    FIREBASE_AUTHDOMAIN: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    FIREBASE_PROJECTID: process.env.REACT_APP_FIREBASE_PROJECTID,
    FIREBASE_STORAGEBUCKET: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    FIREBASE_MESSAGINGSENDERID: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    FIREBASE_APPID: process.env.REACT_APP_FIREBASE_APPID,
    ZITADEL_AUTHORITY: process.env.REACT_APP_ZITADEL_AUTHORITY,
    ZITADEL_CLIENT_ID: process.env.REACT_APP_ZITADEL_CLIENT_ID

};

export default config;
